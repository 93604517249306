import React, { Component } from 'react';
import Layout from '../components/Layout'
import {Helmet} from 'react-helmet';
import Img from 'gatsby-image';
import {graphql, Link} from 'gatsby';
import {MdDateRange} from 'react-icons/md';

class Blog extends Component {
    render() {
        const { data } = this.props
        return (
            <div className="blog">
                <Helmet>
                    <title>บทความ</title>
                </Helmet>
                <Layout>
                    <div className="header-page">
                        <div className="header-page-text">
                            บทความ
                        </div>
                        <div className="header-page-overay"></div>
                        <Img fluid={data.file.childImageSharp.fluid} alt="บทความ" className="header-page-img" />
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-5 text-center">
                                <h3>บทความล่าสุด</h3>
                            </div>
                        </div>
                        <hr />
                        {
                            data.allMarkdownRemark.edges.map(({node},index)=>{
                                let fronmatter= node.frontmatter
                                if(index===0){
                                    return (
                                        <Link to={node.fields.slug} key={index}>
                                            <div className="row mb-5 mt-5 last-blog">
                                                <div className="col-12 col-md-7">
                                                    <Img fluid={fronmatter.image.childImageSharp.fluid} alt={fronmatter.title} className="last-blog-image" />
                                                </div>
                                                <div className="col-12 col-md-5">
                                                    <h2>{fronmatter.title}</h2>
                                                    <p>{node.excerpt}</p>
                                                    <div><MdDateRange /> {fronmatter.date}</div>
                                                </div>
                                            </div>
                                        </Link>
                                    )
                                }
                            })
                        }
                        <div className="row mb-5 blog-item">
                        {
                            data.allMarkdownRemark.edges.map(({node},index)=>{
                                let fronmatter= node.frontmatter
                                if(index!==0){
                                    return (  
                                        <Link to={node.fields.slug} key={index} className="card m-2">
                                            <Img fluid={fronmatter.image.childImageSharp.fluid} alt={fronmatter.title} />
                                            <div className="card-body">
                                                <h5 className="card-title">{fronmatter.title}</h5>
                                                <p className="card-text">{node.excerpt}</p>
                                                <div><MdDateRange /> {fronmatter.date}</div>
                                            </div>
                                        </Link>
                                    )
                                }
                            })
                        }
                        </div>
                    </div>
                </Layout>
            </div>
        );
    }
}

export default Blog;

export const query = graphql`
query{
      file(relativePath: {eq: "blog.jpg"}) {
        childImageSharp {
          fluid{
            ...GatsbyImageSharpFluid
          }
        }
      }
      allMarkdownRemark(sort: { fields: frontmatter___date order: DESC }) {
        edges {
          node {
            id
            frontmatter {
              title
              date
              image {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
            }
            fields {
              slug
            }
            excerpt
          }
        }
      }
  }  
`